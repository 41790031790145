<template>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-12">
        <div class="table-responsive">
          <div class="liguilla">
            <div class="liguilla__round" v-for="(round, ind) in rounds" :key="ind">
              <h5 class="text-center">Rounda {{ ind + 1 }}</h5>
              <hr>
              <div class="liguilla__matches">
                <div class="liguilla__wrapper" v-for="(match, i) in round.matches" :key="i" :class="`round-x-${ind + 1}`">
                  <div class="liguilla__match">
                    <div class="liguilla__team">
                      <span class="liguilla__goals">{{ match.local_goals }}</span>
                      <div class="liguilla__team-name">
                        <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: match.team_local_slug } }">
                          <img :src="match.team_local_image" :alt="match.team_local_name">
                          <span>{{ match.team_local_name }}</span>
                        </router-link>
                      </div>
                    </div>
                    <hr v-if="!match.free">
                    <div class="liguilla__team" v-if="!match.free">
                      <span class="liguilla__goals">{{ match.visit_goals }}</span>
                      <div class="liguilla__team-name">
                        <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: match.team_visit_slug } }">
                          <img :src="match.team_visit_image" :alt="match.team_visit_name">
                        <span>{{ match.team_visit_name }}</span>
                        </router-link>
                      </div>
                    </div>
                    <router-link v-if="!match.free && match.played" :to="{ name: 'DivisionMatchDetail', params: { lang: lang, console: console, divisionSlug: match.division_slug, fixtureId: match.id } }" class="btn liguilla__btn">
                      <i class="fa fa-eye"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="liguilla__round" v-if="champion">
              <h5 class="text-center">{{ $t('champion') }}</h5>
              <hr>
              <div class="liguilla__matches">
                <div class="liguilla__wrapper" :class="`round-x-${championColumns}`">
                  <div class="liguilla__match">
                    <div class="liguilla__team">
                      <div class="liguilla__team-name">
                        <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: champion.team_local_slug } }">
                          <img :src="champion.team_local_image" :alt="champion.team_local_name">
                          <span>{{ champion.team_local_name }}</span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="liguilla">
            <div class="liguilla__round" v-if="third">
              <h5 class="text-center">{{ $t('thirdPlace') }}</h5>
              <hr>
              <div class="liguilla__matches">
                <div class="liguilla__wrapper round-x-1">
                  <div class="liguilla__match">
                    <div class="liguilla__team">
                      <span class="liguilla__goals">{{ third.local_goals }}</span>
                      <div class="liguilla__team-name">
                        <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: third.team_local_slug } }">
                          <img :src="third.team_local_image" :alt="third.team_local_name">
                          <span>{{ third.team_local_name }}</span>
                        </router-link>
                      </div>
                    </div>
                    <hr v-if="!third.free">
                    <div class="liguilla__team" v-if="!third.free">
                      <span class="liguilla__goals">{{ third.visit_goals }}</span>
                      <div class="liguilla__team-name">
                        <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: third.team_visit_slug } }">
                          <img :src="third.team_visit_image" :alt="third.team_visit_name">
                        <span>{{ third.team_visit_name }}</span>
                        </router-link>
                      </div>
                    </div>
                    <router-link v-if="!third.free && third.played" :to="{ name: 'DivisionMatchDetail', params: { lang: lang, console: console, divisionSlug: third.division_slug, fixtureId: third.id } }" class="btn liguilla__btn">
                      <i class="fa fa-eye"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      rounds: [],
      champion: null,
      third: null,
      championColumns: 1
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'currentSeason'
    ])
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_DIVISION', 'home')
  },
  created() {
    this.fetchData()
  },
  watch: {
    currentSeason() {
      this.fetchData()
    }
  },
  methods: {
    updatePositionInRanking(currentPage, perPage) {
      this.positionInRanking = (perPage * (currentPage - 1)) +  1
    },
    fetchData() {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.divisionSlug
      const params = {
        season: this.currentSeason
      }
      this.path = `${lang}/console/${console}/division/${slug}/playoffs`
      this.$axios.get(this.path, { params}).then((response) => {
        const data = response.data
        this.rounds = data.rounds
        this.champion = data.champion
        this.third = data.third
        this.championColumns = this.rounds.length
      })
    },
 
  }
}
</script>
